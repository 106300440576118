import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import classNames from "classnames";

import SearchInstitution from "root/components/SearchInstitution";
import AskSioslifeForm from "root/components/AskSioslifeForm";
import Typography from "root/components/Typography";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";

import styles from "./index.module.css";

const mobileW = 480;

const HomeInstitutions = ({
  translations: { findInstitution, askSioslifeForm, contactUs },
}) => {
  const dimensions = useWindowDimensions();
  const data = useStaticQuery(graphql`
    query {
      institutionsBanner: file(relativePath: { eq: "institutions.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.content}>
          <SearchInstitution
            findInstitution={findInstitution}
            askSioslifeForm={askSioslifeForm}
            color="blue"
          />
        </div>
      </div>

      <div className={classNames(styles.root, styles.knowMore)}>
        <div className={styles.content}>
          <div className={styles.formSection}>
            <div className={styles.formLeft}>
              <div className={styles.formQuestion}>
                <Typography variant="h3" weight="medium" color="blue">
                  {contactUs.question}
                </Typography>
              </div>
              <AskSioslifeForm
                translations={contactUs.askSioslifeForm}
                color="yellow"
              />
            </div>

            <div className={styles.formRight}>
              <div className={styles.imageRoot}>
                <div className={styles.backgroundGrid}>
                  <Grid
                    columns={dimensions.width > mobileW ? 36 : 18}
                    lines={dimensions.width > mobileW ? 18 : 18}
                    color="yellow"
                    opacity={0.5}
                  />
                </div>
                <Img
                  className={styles.institutionsImage}
                  alt="institution"
                  fluid={data.institutionsBanner.childImageSharp.fluid}
                  objectFit="cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

HomeInstitutions.propTypes = {
  translations: PropTypes.shape({
    findInstitution: PropTypes.shape({
      findInstitutionTitle: PropTypes.string.isRequired,
      searchPlaceholder: PropTypes.string.isRequired,
      institutionSiolife: PropTypes.string.isRequired,
      institutionNotSiolife: PropTypes.string.isRequired,
      institutionNotSiolifeQuestion: PropTypes.string.isRequired,
    }).isRequired,
    askSioslifeForm: PropTypes.shape({
      namePlaceholder: PropTypes.string.isRequired,
      emailPlaceholder: PropTypes.string.isRequired,
      phonePlaceholder: PropTypes.string.isRequired,
      institutionNamePlaceholder: PropTypes.string.isRequired,
      institutionPositionPlaceholder: PropTypes.string.isRequired,
      submit: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string.isRequired,
    }).isRequired,
    contactUs: PropTypes.shape({
      question: PropTypes.string.isRequired,
      askSioslifeForm: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};

export default HomeInstitutions;
