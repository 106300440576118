import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import Typography from "root/components/Typography";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";

import styles from "./index.module.css";

const mobileW = 800;

const Hero = ({ translations: { title, subtitle } }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "heroImage.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const dimensions = useWindowDimensions();

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.left}>
          <Img
            className={styles.image}
            alt="hero"
            fluid={data.file.childImageSharp.fluid}
            objectFit="cover"
          />
          <div className={styles.grid}>
            <Grid
              columns={dimensions.width > mobileW ? 36 : 18}
              lines={27}
              color="white"
              opacity={0.25}
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.title}>
            <Typography weight="medium" variant="h1">
              {title}
            </Typography>
          </div>
          <div>
            <Typography>{subtitle}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  translations: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
  }).isRequired,
};

export default Hero;
