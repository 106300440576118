import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";
import classNames from "classnames";

import Typography from "root/components/Typography";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";

import styles from "./index.module.css";

const mobileW = 800;

const HomeValuePropositions = ({
  translations: {
    firstValue,
    firstValueDescription,
    secondValue,
    secondValueDescription,
    thirdValue,
    thirdValueDescription,
  },
}) => {
  const data = useStaticQuery(graphql`
    query {
      valuesImg1: file(relativePath: { eq: "home-values1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      valuesImg1_mobile: file(relativePath: { eq: "home-values1_m.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      valuesImg4: file(relativePath: { eq: "home-values4.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      valuesImg3: file(relativePath: { eq: "home-values3.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const dimensions = useWindowDimensions();

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.firstContent}>
          <div className={styles.leftSide}>
            <div className={styles.firstValueTitle}>
              <Typography variant="h2" weight="medium" color="blue">
                {firstValue}
              </Typography>
            </div>
            <div className={styles.firstValueDescription}>
              <Typography color="black">{firstValueDescription}</Typography>
            </div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.imageRoot}>
              <div className={styles.backgroundGridHorizontal}>
                <Grid
                  columns={dimensions.width > mobileW ? 27 : 18}
                  lines={dimensions.width > mobileW ? 18 : 18}
                  color="yellow"
                  opacity={0.5}
                />
              </div>
              <Img
                className={classNames(styles.familyFriendsImage, styles.image)}
                alt="family and friends"
                fluid={
                  dimensions.width > mobileW
                    ? data.valuesImg1.childImageSharp.fluid
                    : data.valuesImg1_mobile.childImageSharp.fluid
                }
                objectFit="cover"
              />
            </div>
          </div>
        </div>
        <div className={styles.secondContent}>
          <div className={styles.leftSide}>
            <div className={classNames(styles.imageRoot, styles.secondImage)}>
              <div className={styles.backgroundGridVertical}>
                <Grid
                  columns={dimensions.width > mobileW ? 18 : 18}
                  lines={dimensions.width > mobileW ? 27 : 18}
                  color="yellow"
                  opacity={0.5}
                />
              </div>
              <Img
                className={classNames(styles.bodyMindImage, styles.image)}
                alt="body and mind"
                fluid={data.valuesImg4.childImageSharp.fluid}
                objectFit="cover"
              />
            </div>
          </div>
          <div className={styles.leftSide}>
            <div className={styles.secondValueTitle}>
              <Typography variant="h2" weight="medium" color="blue">
                {secondValue}
              </Typography>
            </div>
            <div className={styles.secondValueDescription}>
              <Typography color="black">{secondValueDescription}</Typography>
            </div>
          </div>
        </div>
        <div className={styles.thirdContent}>
          <div className={styles.leftSide}>
            <div className={styles.thirdValueTitle}>
              <Typography variant="h2" weight="medium" color="blue">
                {thirdValue}
              </Typography>
            </div>
            <div className={styles.thirdValueDescription}>
              <Typography color="black">{thirdValueDescription}</Typography>
            </div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.imageRoot}>
              <div className={styles.backgroundGridHorizontal}>
                <Grid
                  columns={dimensions.width > mobileW ? 27 : 18}
                  lines={dimensions.width > mobileW ? 18 : 18}
                  color="yellow"
                  opacity={0.5}
                />
              </div>
              <Img
                className={classNames(styles.nowadaysImage, styles.image)}
                alt="nowadays"
                fluid={data.valuesImg3.childImageSharp.fluid}
                objectFit="cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeValuePropositions.propTypes = {
  translations: PropTypes.shape({
    firstValue: PropTypes.string.isRequired,
    firstValueDescription: PropTypes.string.isRequired,
    secondValue: PropTypes.string.isRequired,
    secondValueDescription: PropTypes.string.isRequired,
    thirdValue: PropTypes.string.isRequired,
    thirdValueDescription: PropTypes.string.isRequired,
  }).isRequired,
};

export default HomeValuePropositions;
