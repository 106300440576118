import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

import Typography from "root/components/Typography";
import Button from "root/components/Button";
import stick from "root/assets/stick.svg";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";
import institutionsIcons from "root/data/institutions/icons.json";
import styles from "./index.module.css";
import ShuffleArray from "../../components/ShuffleArray";

const mobileW = 500;

const Projects = ({
  translations: { knowmore, institutions, home, country, institutionsList },
}) => {
  const dimensions = useWindowDimensions();

  const data = useStaticQuery(graphql`
    query {
      projects1: file(relativePath: { eq: "projects1.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      projects_2: file(relativePath: { eq: "projects_2.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      projects3: file(relativePath: { eq: "projects3.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const [origin, setOrigin] = useState("");

  useEffect(() => {
    if (document !== undefined) {
      setOrigin(document.location.origin);
    }
  }, []);

  const renderInstitutions = () => {
    // in future read the icons from the ES and PT jsons
    const shuffledInstitutions = ShuffleArray(institutionsIcons);

    return shuffledInstitutions.map((icon, index) => {
      return (
        <div className={styles.logo}>
          <img
            alt={`institution${index + 1}`}
            src={`${origin}/assets/${icon.image}`}
          />
        </div>
      );
    });
  };

  return (
    <div>
      <div className={styles.projects}>
        <div className={styles.institutions}>
          <div className={styles.left}>
            <Img
              className={styles.image}
              alt="institutions"
              fluid={data.projects1.childImageSharp.fluid}
              objectFit="cover"
            />
            <div className={styles.grid}>
              <Grid
                columns={dimensions.width > mobileW ? 18 : 9}
                lines={dimensions.width > mobileW ? 37 : 18}
                color="red"
                opacity={0.5}
              />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.title}>
              <Typography variant="h2" color="red" weight="medium">
                {institutions.title}
              </Typography>
            </div>
            <Typography variant="body" color="black">
              {institutions.subtitle}
            </Typography>
            <Link to={institutions.url}>
              <Button color="red" size="small">
                <Typography variant="body">{knowmore}</Typography>
              </Button>
            </Link>
          </div>
        </div>
        <div className={styles.home}>
          <div className={styles.left}>
            <div className={styles.title}>
              <Typography variant="h2" color="purple" weight="medium">
                {home.title}
              </Typography>
            </div>
            <Typography variant="body" color="black">
              {home.subtitle}
            </Typography>
            <Link to={home.url}>
              <Button color="purple" size="small">
                <Typography variant="body">{knowmore}</Typography>
              </Button>
            </Link>
          </div>
          <div className={styles.right}>
            <Img
              className={styles.image}
              alt="home"
              fluid={data.projects_2.childImageSharp.fluid}
              objectFit="cover"
            />
            <div className={styles.grid}>
              <Grid
                columns={dimensions.width > mobileW ? 18 : 9}
                lines={dimensions.width > mobileW ? 37 : 18}
                color="purple"
                opacity={0.5}
              />
            </div>
          </div>
        </div>
        <div className={styles.country}>
          <div className={styles.left}>
            <Img
              className={styles.image}
              alt="country"
              fluid={data.projects3.childImageSharp.fluid}
              objectFit="cover"
            />
            <div className={styles.grid}>
              <Grid
                columns={dimensions.width > mobileW ? 18 : 9}
                lines={dimensions.width > mobileW ? 36 : 18}
                color="green"
                opacity={0.5}
              />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.title}>
              <Typography variant="h2" color="green" weight="medium">
                {country.title}
              </Typography>
            </div>
            <Typography variant="body" color="black">
              {country.subtitle}
            </Typography>
            <Link to={country.url}>
              <Button color="green" size="small">
                <Typography variant="body">{knowmore}</Typography>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      {/* 
      
        INSTITUTIONS LIST

      */}
      <div className={styles.institutionsList}>
        <Typography variant="h3" color="blue" weight="medium">
          {institutionsList}
        </Typography>
        <div className={styles.logos}>{renderInstitutions()}</div>
        <img className={styles.stick} alt="stick" src={stick} />
      </div>
    </div>
  );
};

Projects.propTypes = {
  translations: PropTypes.shape({
    knowmore: PropTypes.string.isRequired,
    institutions: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    home: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    country: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    institutionsList: PropTypes.string.isRequired,
  }).isRequired,
};

export default Projects;
